var $class="se2--content-support-module",$name="ContentSupportModule",$path="app/components/ContentSupportModule/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import switchFocusToTargetElement from 'app/utilities/switchFocusToTargetElement';

import forEach from 'lodash/forEach';

import {
  lastSupportLinkSelector,
  linkTagName,
  supportLinkSelector,
} from './config';

import getPageUrl from './utilities/getPageUrl';

export default shell.registerComponent($this, ({
  addEventListener, configs, mount,
}) => {
  mount((element) => {
    const { css, keyNames } = configs;
    const supportLinks = element.querySelectorAll(supportLinkSelector);

    if (!supportLinks.length) return;

    forEach(
      supportLinks,
      (link) => {
        const linkUrl = new URL(
          link.href,
          window.location.origin,
        );

        if (linkUrl.href === getPageUrl()) {
          link.parentElement.classList.add(css.classNames.disabled);
        }
      },
    );

    addEventListener(element, 'keydown', (event) => {
      if (isUsingKeyboard()) {
        const { code, target, currentTarget } = event;

        if (code === keyNames.arrowRight || code === keyNames.arrowDown) {
          event.preventDefault();
          switchFocusToTargetElement(
            target.parentElement?.nextElementSibling?.querySelector(linkTagName),
            target,
          );
        } else if (code === keyNames.arrowLeft || code === keyNames.arrowUp) {
          event.preventDefault();
          switchFocusToTargetElement(
            target.parentElement?.previousElementSibling?.querySelector(linkTagName),
            target,
          );
        } else if (code === keyNames.home) {
          switchFocusToTargetElement(
            currentTarget.querySelector(supportLinkSelector),
            target,
          );
        } else if (code === keyNames.end) {
          switchFocusToTargetElement(
            currentTarget.querySelector(lastSupportLinkSelector),
            target,
          );
        }
      }
    });
  });
});
